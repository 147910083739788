<template lang="pug">
#page-not-allowed
  error-page(empty-description="Доступ запрещен" status-code="403" )
    template(#errorMessage) Доступ к запрашиваемой странице ограничен
    template(#errorDescription) В вашем тарифе не предусмотрен доступ к запрашиваемой странице.

</template>

<script lang="ts">
import { defineComponent } from "vue";
import ErrorPage from "@/components/pages/error/ErrorPage.vue";

export default defineComponent({
  name: "PageNotAllowed",
  components: {
    ErrorPage,
  },
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";

#page-not-allowed {
  @include adaptive-view;
}
</style>
